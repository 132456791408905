import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { getUserSefApiStatus } from '../utils/utils.js'
import { SubmitApiKey } from '../components/submitApiKey.jsx'
import { ListaFaktura } from '../components/listaFaktura.jsx'
const Loading = styled.section``
export const Homepage = ({userApiStatus, updateUserSefkey}) => {
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [userSefApiStatus, setUserSefApiStatus] = useState(userApiStatus)
    const [isNotAuthorized, setIsNotAuthorized] = useState(false)
    if(isDataLoaded === false) {
        getUserSefApiStatus().then((result) => {
            setIsDataLoaded(true)

            if(result === null) {
                //Korisnik nema validan token
                setIsNotAuthorized(true)
                console.log('RESULT IS NULL')
            } else {
                setUserSefApiStatus(result)
                console.log('Sef api status', result)
            }
        })
    }

    useEffect(() => {
        setUserSefApiStatus(userApiStatus)
    },[userApiStatus])

    return (
        <>
            {(!isDataLoaded) && <Loading className='card mb-4'><div className="card-body">Provera API Ključa</div></Loading>}
            {(isDataLoaded && !userSefApiStatus && !isNotAuthorized) && <SubmitApiKey updateUserSefkey={updateUserSefkey}/>}
            {(!isNotAuthorized && userSefApiStatus) && <ListaFaktura userApiStatus={userSefApiStatus}/>}
            {isNotAuthorized && <>Vaša sesija je istkla. Molimo Vas da se ponovo ulogujete u svoj Moje Fakture nalog.</>}
        </>
    )
}

Homepage.propTypes = {
    userApiStatus: PropTypes.bool,
    updateUserSefkey: PropTypes.func
}