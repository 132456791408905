import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { getSingleUserInvoice, setNewBuyerEmail, updateOrderArticleJm, updateOrderArticleTaxExemption, getPoreskaOslobodjenja, setDocumentContractNumber, setBuyerJbkjs, sendInvoiceToSef, setNewBuyerJMBG } from '../utils/utils.js'
import { Typography } from '@mui/material'
import { TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import "../styles/Dokument.css"
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const JMForma = styled.div``
const TaxExemptionForma = styled.div``

let jmForme = []
let texForme = []
let sefResponses = []

export const Dokument = ({userApiStatus, updateUserSefkey}) => {
    const { invoiceId } = useParams()
    console.log('INVOJS ID', invoiceId)
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [kupac, setKupac] = useState('')
    const [documentLabel, setDocumentLabel] = useState('')
    const [brojDokumenta, setBrojDokumenta] = useState('')
    const [spremnoZaSlanje, setSpremnoZaSlanje] = useState(null)
    const [problemi, setRazlozi] = useState()
    const [buyerEmailMissing, setBuyerEmailMissing] = useState(null)
    //useEffect((jmForme)=>{},[jmForme])
    //Form fields

    const [newBuyerEmail, setBuyerEmail] = useState('')
    const [jmForms, setJmForms] = useState([])
    const [taxExForms, setTaxForms] = useState([])
    const [poreskaOslobodjenja, setPoreskaOslobodjenja] = useState([])
    const [buyerJMBG, setBuyerJMBG] = useState('')
    const [jmbgMissing, setJmbgMissing] = useState(false)
    const [missingJBKJS, setJBKJSMissing] = useState(false)
    const [missingBrojUgovora, setBrojUgovoraMissing] = useState(false)
    const [jBKJS, setJBKJS] = useState()
    const [sentToSef, setSentToSef] = useState(false)
    const [hasSefResponses, setHasSefResponses] = useState(false)
    const [brojUgovora, setBrojUgovora] = useState()
    const [posaljiDokumentUCrf, setPosaljiDokumentUCrf] = useState()
    const [sendingInProgress, setSendingInProgress] = useState(false)

    const [jmbgUpdateInProgress, setJmbgUpdateInProgress] = useState(false)
    const [jmbgUpdateHasError, setJmbgUpdateHasError] = useState(false)
    const [jmbgUpdateErrorLabel, setJmbgUpdateErrorText] = useState('')

    const handleSubmitBuyerEmail = (e) => {
        console.log('Setting new buyer email', newBuyerEmail)
        // alert(this.state.value)
        const newInvoiceData = setNewBuyerEmail(invoiceId, newBuyerEmail).then((result) => { 
            console.log('New invoice info nakon submita emaila', result)
            if(result !== false) {
                let invoice = result[0]
                updatePageStates(invoice)
            }
            return result
        })
        
        // e.preventDefault()
    }

    const handleSubmitBuyerJMBG = (e) => {
        console.log('Setting new buyer JMBG', buyerJMBG)
        // alert(this.state.value)
        setJmbgUpdateInProgress(true)
        setJmbgUpdateHasError(false)
        setJmbgUpdateErrorText('')

        const newInvoiceData = setNewBuyerJMBG(invoiceId, buyerJMBG).then((result) => { 
            console.log('New invoice info nakon submita jmbg-a', result)
            if(result !== false) {
                let invoice = result[0]
                updatePageStates(invoice)
            } else {
                setJmbgUpdateHasError(true)
                setJmbgUpdateErrorText('Došlo je do greške prilikom snimanja JMBG-a. Upišite samo brojeve.')
            }
            setJmbgUpdateInProgress(false)
            return result
        })
        
        // e.preventDefault()
    }

    
    const handleSubmitJm = (e) => {
        console.log('Setting JM za order article ID', e.target.jm_order_article_id.value, e.target.jedinice_mere.value)
        let orderArticleId = e.target.jm_order_article_id.value
        let jmId = e.target.jedinice_mere.value
        const newJmForOrderArticle = updateOrderArticleJm(invoiceId, orderArticleId, jmId).then((result) => {
            console.log('New invoice after jm update', result)
            if(result !== false) {
                let invoice = result[0]
                updatePageStates(invoice)
            }
            return result
        })
        e.preventDefault()
    }

    const handleSubmitTaxExemption = (e) => {
        let orderArticleId = e.target.tax_exemption_order_article_id.value
        let taxExemptionId = e.target.tax_exemption_id.value
        let brojResenja = e.target.tax_exemption_resenje.value
        console.log('Setting Tax Exemption za order article ID', orderArticleId,taxExemptionId)

        updateOrderArticleTaxExemption(invoiceId, orderArticleId, taxExemptionId, brojResenja).then((result) => {
            console.log('New invoice after tax exemption update', result)
            if(result !== false) {
                let invoice = result[0]
                updatePageStates(invoice)
            }
            return result
        })
        e.preventDefault()
    }
    const handleSubmitBrojUgovora = () => {
        console.log('Setujem broj ugovora za invoice ide', brojUgovora, invoiceId)
        setDocumentContractNumber(invoiceId, brojUgovora).then((result) => {
            console.log('New invoice after contract update', result)
            if(result !== false) {
                let invoice = result[0]
                updatePageStates(invoice)
            }
            return result
        })
    }

    const handleSubmitJBKJS = () => {
        console.log('Setujem jbkjs broj za invoice ide', invoiceId)
        setBuyerJbkjs(invoiceId, jBKJS).then((result) => {
            console.log('New invoice after JBKJS update', result)
            if(result !== false) {
                let invoice = result[0]
                updatePageStates(invoice)
            }
            return result
        })
    }

    const handleSubmitPosaljiuSEF = () => {
        setSendingInProgress(true)
        sendInvoiceToSef(invoiceId, posaljiDokumentUCrf).then((result) => {
            console.log('Invoice nakon slanja u SEF', result)
            if(result !== false) {
                let invoice = result[0]
                updatePageStates(invoice)
            }
            setSendingInProgress(false)
            return result
        })
    }

    function updatePageStates(invoice) {
        let razlozi = []
        jmForme = []
        setKupac(invoice.buyer.naziv)
        setDocumentLabel(invoice.label)
        setBrojDokumenta(invoice.broj_fakture)

        if(invoice.invoice_already_in_sef === false) {
            setSentToSef(false)
        } else {
            setSentToSef(true)
        }

        let spremno = true

        
        if(invoice.sef_responses.length > 0) {
            setHasSefResponses(true)
            sefResponses = []
            let responseKey =1;
            for (const singleResponse of invoice.sef_responses) {
                let srStatus = singleResponse.status
                let srMessage = ''
                let srVreme = singleResponse.response_time
                let srLabel = 'Greška'
                if (srStatus == '200') {
                    srLabel = 'Potvrda'
                    //{"InvoiceId":713264,"PurchaseInvoiceId":713264,"SalesInvoiceId":772540}
                    let srData = JSON.parse(singleResponse.raw_response)
                    srMessage = 'InvoiceId: '+srData.InvoiceId  + ' PurchaseInvoiceId: '+ srData.PurchaseInvoiceId + ' SalesInvoiceId: '+srData.SalesInvoiceId
                } else {
                    srMessage = JSON.parse(singleResponse.raw_response).Message
                }
                sefResponses.push(<div key={responseKey}><h4>{srLabel}</h4><p>{srVreme}: {srMessage}</p></div>)
                responseKey++
            }
        } else {
            console.log('Odgovora iz sefa', invoice.sef_responses.length, hasSefResponses, sentToSef)
        }

        let requiredItems = invoice.verification_details.required
        let optionalItems = invoice.verification_details.optional

        if(!optionalItems.JBKJS_broj) {
            //JBKJS_broj is missing
            setJBKJSMissing(true)
        } else {
            setJBKJSMissing(false)
        }
        if(!optionalItems.broj_ugovora) {
            //JBKJS_broj is missing
            setBrojUgovoraMissing(true)
        } else {
            setBrojUgovoraMissing(false)
        }

        if(!requiredItems.buyer_email) {
            spremno = false
            setBuyerEmailMissing(true)
            razlozi.push("Email adresa kupca nije definisana.")
        } else {
            setBuyerEmailMissing(false)
        }


        if(requiredItems.stavke.jm.length > 0) {
            console.log('PREOSTALO JM FORMI', requiredItems.stavke.jm.length)
            spremno = false
            let formName = ''
            for (let i =0; i<requiredItems.stavke.jm.length; i++) {
                let stavka = requiredItems.stavke.jm[i]
                console.log('DODAJEM FORMU', 'Izmena JM za OAID '+stavka.order_article_id)
                formName = 'Izmena JM za OAID '+stavka.order_article_id
                let jmLabelCurrent = stavka.jm
                console.log(jmLabelCurrent)
                if(jmLabelCurrent == '' || jmLabelCurrent === null) {
                    jmLabelCurrent = 'nije definisano'
                }
                jmForme.push(
                    <JMForma key={i} style={{width: '100%', marginBottom: '15px'}}>
                    <form onSubmit={e => {handleSubmitJm(e)}} name={formName}>
                        <label style={{display: "flex", justifyContent: "space-between", alignItems: 'center'}}>
                            {stavka.naziv} (trenutno: {jmLabelCurrent})
                            <div>
                            <input type="hidden" name="jm_order_article_id" value={stavka.order_article_id}></input>
                            <select name='jedinice_mere' style={{width: '75px', textAlign: 'center'}}>
                                <option value='1'>kom</option>
                                <option value='2'>kg</option>
                                <option value='3'>km</option>
                                <option value='4'>g</option>
                                <option value='5'>m</option>
                                <option value='6'>l</option>
                                <option value='7'>t</option>
                                <option value='8'>m2</option>
                                <option value='9'>m3</option>
                                <option value='10'>min</option>
                                <option value='11'>h</option>
                                <option value='12'>d</option>
                                <option value='13'>M</option>
                                <option value='14'>god</option>
                                <option value='15'>kWh</option>
                            </select>
                            <input type="submit" value="Snimi" style={{marginLeft: '15px'}}/>
                            </div>
                        </label>
                    </form>
                    </JMForma>)
            }
            console.log('UKUPNO JM FORMI IMA', jmForme.length)
            setJmForms(jmForme)
            razlozi.push("Nedostaju ispravne jedinice mere za jednu ili vise stavki.")
        } else {
            setJmForms([])
        }
        if(requiredItems.stavke.tax_exemption.length > 0) {
            spremno = false
            console.log('PREOSTALO TAX EXEMPTION FORMI', requiredItems.stavke.tax_exemption.length)
            let formName = ''
            texForme = []

            for (let i =0; i<requiredItems.stavke.tax_exemption.length; i++) {
                let stavka = requiredItems.stavke.tax_exemption[i]
                console.log('DODAJEM FORMU', 'Izmena TX za OAID '+stavka.order_article_id)
                formName = 'Izmena TE za OAID '+stavka.order_article_id
                texForme.push(<TaxExemptionForma key={i}>
                    <form onSubmit={e => {handleSubmitTaxExemption(e)}} name={formName} style={{display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: '20px'}}>
                        <label>
                            {stavka.naziv} (trenutno: {stavka.jm})
                            <input type="hidden" name="tax_exemption_order_article_id" value={stavka.order_article_id}></input>
                            <select name='tax_exemption_id' style={{width: '150px', textAlign: 'center'}}>
                                {poreskaOslobodjenja.map(taxExemption => <option key={taxExemption.id} value={taxExemption.id}>({taxExemption.category}){taxExemption.text}</option>)}
                            </select>
                        </label>
                        <p> </p>
                        <label>
                            Upišite broj poreskog rešenja na osnovu kojeg koristite ovaj član zakona o PDV-u.
                            <input type="text" name="tax_exemption_resenje"/>
                        </label>
                        <input type="submit" value="Snimi" style={{marginTop: '15px'}}/>
                    </form>
                </TaxExemptionForma>)
            }
            console.log('UKUPNO TAX FORMI IMA', texForme.length)
            setTaxForms(texForme)
            razlozi.push("Nisu definisane kategorije poreskih oslobodjenja za jednu ili vise stavki.")
        } else {
            setTaxForms([])
        }

        if(invoice.buyer.pib === null && invoice.buyer.tip === 'PG') {
            spremno = false
            razlozi.push('Nedostaje JMBG kupca / poljoprivrednog gazdinstva')
            setJmbgMissing(true)
        } else {
            setJmbgMissing(false)
        }
        
        setRazlozi(razlozi.join(" "))
        setSpremnoZaSlanje(spremno)
    }
    
    if(!isDataLoaded) {
        getPoreskaOslobodjenja().then((result) => {
            console.log('POreska oslobodjenja', result)
            if(result !== false) {
                setPoreskaOslobodjenja(result)
            }
        })

        getSingleUserInvoice(invoiceId).then((result)=>{
            console.log(result)
            if(result !== false) {
                let invoice = result[0]
                setIsDataLoaded(true)
                updatePageStates(invoice)
            }         
        })
    }
 
    return (
        <Box display="flex" alignItems="center" justifyContent="center" textAlign="center" flexDirection="column">
            <Typography variant="h4">{documentLabel} {brojDokumenta}</Typography>
            <Typography variant="h5">{kupac}</Typography>
            <Divider style={{marginBottom: '25px', marginTop: '25px'}} width="100%"/>
            {(spremnoZaSlanje === null) && <Typography variant="h6">Provera spremnosti fakture za slanje u Sistem Elektronskih Faktura (SEF)</Typography>}
            {(spremnoZaSlanje === false) && (
                <>
                    <Typography variant="h6" >Faktura nije spremna za slanje u SEF</Typography>
                    <Alert severity="warning"><b>Razlozi: </b>{problemi}</Alert>
                </>
            )}
            {(spremnoZaSlanje && !sentToSef) && <>
                <Typography variant="h6">Faktura je spremna za slanje u SEF </Typography>
                <FormControl style={{display: 'flex', width: '750px', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: '25px'}}>
                    <FormLabel id="demo-row-radio-buttons-group-label">Pošalji dokument u CRF?</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e)=>setPosaljiDokumentUCrf(e.target.value)}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Da" />
                        <FormControlLabel value="No" control={<Radio />} label="Ne" />
                    </RadioGroup>
                    <LoadingButton onClick={handleSubmitPosaljiuSEF} disabled={!posaljiDokumentUCrf} loading={sendingInProgress} variant="outlined">
                            Snimi
                    </LoadingButton>
                </FormControl>
            </>}
            
            {(spremnoZaSlanje === false && jmForms.length > 0) && <>
                <Divider style={{marginBottom: '25px', marginTop: '25px'}} width="100%"/>
                <Typography  variant="h6" style={{marginBottom: '20px'}}>Odaberite ispravnu jedinicu mere za stavku</Typography>
                <Box style={{display: 'flex', width: '600px', alignItems: 'baseline', flexDirection: 'column'}}>
                    {jmForms}
                </Box>
            </>}
            
            {(spremnoZaSlanje === false && taxExForms.length > 0) && <>
                <Divider style={{marginBottom: '25px', marginTop: '25px'}} width="100%"/>
                <Typography variant="h6" style={{marginBottom: '20px', marginTop: '20px'}}>Poreska oslobodjenja</Typography>
                <Box style={{display: 'flex', width: "1000px", alignItems: 'baseline', flexDirection: 'column'}}>
                    {taxExForms}
                </Box>
            </>}
            {(spremnoZaSlanje === false && buyerEmailMissing === true) && 
                <Box textAlign="center" margin="30px 0 30px 0" display="flex" alignItems="center" width="750px" justifyContent="space-between">
                    <Typography>Upišite email adresu primaoca fakture</Typography>
                    <Box style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>
                        <TextField size="small" onChange={e => setBuyerEmail(e.target.value)} value={newBuyerEmail} label="Email" variant="outlined" />
                        {/* podesiti loading state ako je to potrebno */}
                        <LoadingButton onClick={()=>handleSubmitBuyerEmail(newBuyerEmail)} loading={false} disabled={!newBuyerEmail} variant="outlined">
                            Snimi
                        </LoadingButton>
                    </Box>
                </Box>
            }

            {(spremnoZaSlanje === false && jmbgMissing === true) && <>
                
                <Box textAlign="center" margin="30px 0 10px 0" display="flex" alignItems="center" width="750px" justifyContent="space-between">
                    <Typography>Upišite JMBG primaoca fakture za poljoprivredno gazdinstvo</Typography>
                    <Box style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>
                        <TextField size="small" onChange={e => setBuyerJMBG(e.target.value)} value={buyerJMBG} label="JMBG" variant="outlined" />
                        {/* podesiti loading state ako je to potrebno */}
                        <LoadingButton onClick={()=>handleSubmitBuyerJMBG(buyerJMBG)} loading={jmbgUpdateInProgress} disabled={!buyerJMBG} variant="outlined">
                            Snimi
                        </LoadingButton>
                    </Box>
                </Box>
                <Box textAlign="center" margin="0 0 30px 0" display="flex" alignItems="center" width="750px" justifyContent="space-between">
                    {(jmbgUpdateHasError) && <Alert severity='error'>{jmbgUpdateErrorLabel}</Alert>}
                </Box>
            </>
            }
            {((missingJBKJS ||  missingBrojUgovora) && !sentToSef) && <>
            <Divider style={{marginBottom: '25px', marginTop: '25px'}} width="100%"/>
            <Typography variant="h6" style={{marginBottom: '20px', marginTop: '20px'}}>Opcioni parametri</Typography></> }
            {(missingJBKJS && !sentToSef) && <>
                <Box textAlign="center" margin="30px 0 30px 0" display="flex" alignItems="center" width="750px" justifyContent="space-between">
                    <Typography>Upisite JBKJS broj kupca</Typography>
                    <Box style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>
                        <TextField size="small" onChange={e => setJBKJS(e.target.value)} value={jBKJS} label="JBKJS" variant="outlined" />
                        {/* podesiti loading state ako je to potrebno */}
                        <LoadingButton onClick={()=>handleSubmitJBKJS()} loading={false} disabled={!jBKJS} variant="outlined">
                            Snimi
                        </LoadingButton>
                    </Box>
                </Box>
            </>}

            {(missingBrojUgovora && !sentToSef) && <>
                 <Box textAlign="center" margin="30px 0 30px 0" display="flex" alignItems="center" width="750px" justifyContent="space-between">
                    <Typography>Upisite broj ugovora za koji se izdaje ovaj dokument</Typography>
                    <Box style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>
                        <TextField size="small" onChange={e => setBrojUgovora(e.target.value)} value={brojUgovora} label="Broj Ugovora" variant="outlined" />
                        {/* podesiti loading state ako je to potrebno */}
                        <LoadingButton onClick={()=>handleSubmitBrojUgovora()} loading={false} disabled={!brojUgovora} variant="outlined">
                            Snimi
                        </LoadingButton>
                    </Box>
                </Box>
                <Divider style={{marginBottom: '25px', marginTop: '25px'}} width="100%"/>
            </>}
            {sentToSef && <><h3>Faktura je snimljena u SEF. </h3></>}
            {hasSefResponses && <><h3>Odgovori iz SEF-a</h3>{sefResponses}</>}
            
            
        </Box>
    )
}



Dokument.propTypes = {
    userApiStatus: PropTypes.bool,
    updateUserSefkey: PropTypes.func
}