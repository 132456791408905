import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import { getUserSefApiStatus } from '../utils/utils.js'


export const SubmitApiKey = ({updateUserSefkey, isConfig}) => {
    const [sefApiKey, setSefApiKey] = useState('')
    const [isKeySetted, setIsKeySetted] = useState(false)
    // da li je potreban error ovde?
    const [hasError, setError] = useState(null)
    const [errorLabel, setErrorLabel] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = (e) => {
        console.log('ALOOO', sefApiKey)
        setIsLoading(true)
        updateUserSefkey(sefApiKey).then((res) => {
            if(res === false) {
                setError(true)
                setErrorLabel('Došlo je do greške prilikom izmene API ključa. Molimo Vas pokušajte ponovo. Ukoliko problem nastavi da se pojavljuje, kontaktirajte podršku.')
            } else {
                setError(false)
                setErrorLabel('API ključ je uspešno konfigurisan.')
            }
            setIsLoading(false)       
        })
    }

    let introLabel = 'API ključ za SEF nije podešen. Morate podesiti svoj API ključ pre nego što možete koristiti eMF modul.'
    if(isConfig === true) {
        introLabel = 'Podesite svoj SEF API ključ.'
    }


    return (
        <>
        <Box textAlign="center" margin="30px 0 30px 0">
            <Typography>{introLabel}</Typography>
            <Box style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center', marginTop: '15px'}}>
                <TextField size="small" onChange={e => setSefApiKey(e.target.value)} value={sefApiKey} label="Sef ključ" variant="outlined" />
                {/* podesiti loading state ako je to potrebno */}
                <LoadingButton onClick={()=>handleSubmit(sefApiKey)} loading={isLoading} disabled={!sefApiKey} variant="outlined">
                    Snimi
                </LoadingButton>
                {(hasError === true) && <Alert severity='error'>{errorLabel}</Alert>}
                {(hasError === false) && <Alert severity='success'>{errorLabel}</Alert>}
            </Box>
        </Box>
        <Divider style={{marginBottom: '30px'}}/>
        </>

    )
}

SubmitApiKey.propTypes = {
    updateUserSefkey: PropTypes.func,
    isConfig: PropTypes.bool
}