import React, { useState } from 'react'
import { checkApiStatus } from '../utils/utils.js'

export const StatusCheck = () => {
    const [apiStatus, setApiStatus] = useState('LIVE')

    const updateTimer = async () => {
        console.log('api check')
        checkApiStatus().then((status) => {
            if(status === true) {
                setApiStatus('LIVE')
            } else {
                setApiStatus('API trenutno ne radi')
            }

        })
        setTimeout(updateTimer, 30000);
    }
    updateTimer()
    return (
        <header class="page-header">
			<h2>eMF - Upravljanje elektronskim fakturama ({apiStatus})</h2>
		</header>
    )
}