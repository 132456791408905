import logo from './logo.svg'
import './App.css';
import { React, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import { Homepage } from './pages/Homepage.jsx'
import { Dokument } from './pages/Dokument.jsx'
import { SubmitApiKey } from './components/submitApiKey.jsx'
import { StatusCheck } from './components/StatusCheck.jsx'
import { postUpdateUserSefKey } from './utils/utils.js'


function App() {
  if (window.location.hostname !== 'localhost' && window.location.hostname == 'emf.mojefakture.com') {
    console.log = () => {}
  }

  const [userApiStatus, setUserApiStatus] = useState(false)
  const [userApiStatusLoaded, setApiStatusLoaded] = useState(false)

  const updateUserSefkey = async (sefKey) => {
    const updateSefKeyStatus = await postUpdateUserSefKey(sefKey)
    setUserApiStatus(updateSefKeyStatus)
    return updateSefKeyStatus
  }

  
  return (
    <>
    <StatusCheck />
    <Router>
      <Routes>
        <Route path={'/'} element={<Homepage userApiStatus={userApiStatus} updateUserSefkey={updateUserSefkey}/>}></Route>
        <Route path={'/podesavanja'} element={<SubmitApiKey updateUserSefkey={updateUserSefkey} isConfig={true}/>}></Route>
        <Route path={'/dokument'}>
          <Route path={':invoiceId'} element={<Dokument/>} />
        </Route>
      </Routes>
    </Router></>
  )
}

export default App;
